import loading from "../assets/image/25.gif";
import { status_file, regionRole } from "./text.json";

export const inputTypes = {
  TEXT: "text",
  PASSWORD: "password",
  NUMBER: "number",
  TEXTAREA: "textarea",
  SWITCH: "switch",
  SELECT: "select",
  RADIO: "radio",
  CHECKBOX: "checkbox",
  DATE: "date",
  DATETIME: "datetime",
  QUERY_SELECT: "query-select",
  DELETE: "delete",
  TIME: "time",
};

export const isBank = {
  DIRECT: 1,
  BANK: 2,
};

export const sex = {
  FEMALE: 1,
  MALE: 2,
  NOT_KNOW: 3,
};
export const audit = {
  PROVINCE: 1,
  DISTRICT: 2,
  WARDS: 3,
};
export const support_status = {
  PROCESSING: 1,
  APPROVED: 2,
  REJECT: 3,
  DRAFT: 4,
  RETURN_DATA: 6,
  WAITING_RECEIVE: 10,
};
export const INDIVIDUAL = {
  INDIVIDUAL: 1,
  ORGANIZATION: 2,
};
export function CheckType(type) {
  if (type == audit.PROVINCE) {
    return "Tỉnh/Thành phố";
  }
  if (type == audit.DISTRICT) {
    return "Quận/Huyện";
  }
  if (type == audit.WARDS) {
    return "Xã/Phường";
  }
}

export function CheckSupportStatus(status, apply_date, expiration_date) {
  if (status == support_status.PROCESSING) {
    return status_file.processing;
  }
  if (status == support_status.APPROVED) {
    return status_file.approved;
  }
  if (status == support_status.REJECT) {
    return status_file.reject;
  }
  if (status == support_status.DRAFT) {
    return status_file.draft;
  }
  if (status == support_status.RETURN_DATA) {
    return status_file.return_data;
  }
}

export function expiration_date_count(expiration_date) {
  const date = new Date();
  const nextDate = new Date(expiration_date);
  if (date.getTime() > nextDate.getTime()) {
    return "Quá hạn xử lý hồ sơ";
  } else {
    const days = Math.floor((nextDate - date) / 1000 / 60 / 60 / 24);
    return days + " Ngày";
  }
}

export const loadingStyle = {
  backgroundImage: "url(" + loading + ")",
  backgroundRepeat: "no-repeat",
  backgroundColor: "#f3f3f3",
  backgroundPosition: "center",
  opacity: 0.5,
};

export const color = {
  PRIMARY: "#1976d2",
  SECONDARY: "#dc004e",
  SUCCESS: "#4caf50",
  ERROR: "#f44336",
  WARNING: "#ff9800",
  INFO: "#2196f3",
  LINE: "#e0e0e0",
};
/**
 * @return {string}
 */

export function checkPerm(perms, perm) {
  return perms?.some((x) => x.name == perm);
}
export const RegionType = {
  PROVINCE: 1,
  DISTRICT: 2,
  WARD: 3,
};

export const DocumentType = {
  document: 1,
  otherDocument: 2,
};

export const isCheck = {
  TRUE: 2,
  FALSE: 1,
};

export const isDevotee = {
  TRUE: 2,
  FALSE: 1,
};

export const isRelative = {
  TRUE: 2,
  FALSE: 1,
};

export const isPerson = {
  IS_DEVOTEE: "is_devotee",
  IS_RELATIVE: "is_relative",
  IS_PAY: "is_pay",
  TWO_SELECT: "two_select",
};

export const isWelfare = {
  IS_FOR_DEVOTEE: "is_for_devotee",
  IS_FOR_RELATIVE: "is_for_relative",
};

export const isActive = {
  IS_ACTIVE: "is_active",
  IS_UN_ACTIVE: "is_un_active",
};

export function bhyt_type_list() {
  const list_data = [
    "Người hoạt động cách mạng trước ngày 01 tháng 01 năm 1945",
    "Thân nhân của người hoạt động cách mạng trước ngày 01 tháng 01 năm 1945 từ trần",
    "Người hoạt động cách mạng  từ ngày 01 tháng 01năm 1945 đến ngày khởi nghĩa tháng Tám năm 1945",
    "Thân nhân của người hoạt động cách mạng  từ ngày 01 tháng 01 năm 1945 đến ngày khởi nghĩa tháng Tám năm 1945 từ trần",
    "Thân nhân liệt sĩ (bao gồm cả vợ hoặc chồng liệt sĩ lấy chồng hoặc vợ khác)",
    "Bà mẹ Việt Nam anh hùng",
    "Người phục vụ Bà mẹ Việt Nam anh hùng",
    "Anh hùng Lực lượng vũ trang nhân dân, Anh hùng Lao động trong thời kỳ kháng chiến",
    "Thân nhân của Anh hùng Lực lượng vũ trang nhân dân, Anh hùng Lao động trong thời kỳ kháng chiến",
    "Thương binh, người hưởng chính sách như thương binh",
    "Thương binh loại B",
    "Người phục vụ thương binh, người hưởng chính sách như thương binh, thương binh B, bệnh binh có tỷ lệ tổn thương cơ thể từ 81% trở lên ",
    "Thân nhân của thương binh, người hưởng chính sách như thương binh, thương binh B, bệnh binh có tỷ lệ tổn thương cơ thể từ 61% trở lên ",
    "Bệnh binh",
    "Bệnh binh hạng 3 (BB3)",
    "Người phục vụ bệnh binh có tỷ lệ tổn thương cơ thể từ 81% trở lên",
    "Thân nhân của bệnh binh có tỷ lệ tổn thương cơ thể từ 61% trở lên",
    "Người hoạt động kháng chiến bị nhiễm chất độc hóa học",
    "Người phục vụ người hoạt động kháng chiến bị nhiễm chất độc hóa học có tỷ lệ tổn thương cơ thể từ 81% trở lên",
    "Con đẻ có tỷ lệ tổn thương cơ thể từ 61% trở lên của người hoạt động kháng chiến bị nhiễm chất độc hóa học đang hưởng trợ cấp hàng tháng",
    "Thân nhân của người hoạt động kháng chiến bị nhiễm chất độc hóa học có tỷ lệ tổn thương cơ thể từ 61% trở lên",
    "Người hoạt động cách mạng, kháng chiến, bảo vệ Tổ quốc, làm nghĩa vụ quốc tế bị địch bắt tù, đày",
    "Người hoạt động kháng chiến giải phóng dân tộc, bảo vệ Tổ quốc và làm nghĩa vụ quốc tế, người có công giúp đỡ cách mạng được tặng thưởng Huy chương kháng chiến",
    "Người có công giúp đỡ cách mạng đang hưởng trợ cấp hàng tháng",
    "Người có công giúp đỡ cách mạng được tặng thưởng Huy chương kháng chiến",
    "Thanh niên xung phong thời kỳ chống Pháp (theo QĐ số 170/2008/QĐ-TTg)",
  ];
  let array = [];
  list_data.map((value) => {
    array.push({
      label: value,
      value: value,
    });
  });
  return array;
}

export function date() {
  const date = [];
  for (let i = 1; i <= 31; i++) {
    const inner_obj = { label: i, value: i };
    date.push(inner_obj);
  }
  return date;
}
export function month() {
  const month = [];
  for (let i = 1; i <= 12; i++) {
    const inner_obj = { label: `Tháng ${i}`, value: i };
    month.push(inner_obj);
  }
  return month;
}
export function year(dead) {
  const year = [];
  for (
    let i = dead == "dead" ? 1900 : 1890;
    i <= new Date().getFullYear();
    i++
  ) {
    const inner_obj = { label: i, value: i };
    year.push(inner_obj);
  }
  return year;
}

export const isFrequencyType = {
  ONE_TIME: 1,
  ONE_MONTH: 2,
  ONE_YEAR: 3,
  ONE_SENIORITY: 4,
  TWO_YEAR: 5,
  THREE_YEAR: 6,
  FOUR_YEAR: 7,
  FIVE_YEAR: 8,
};

export const FrequencyType = [
  { label: "1 lần", value: 1 },
  { label: "1 tháng", value: 2 },
  { label: "1 năm", value: 3 },
  { label: "1 thâm niên", value: 4 },
  { label: "2 năm", value: 5 },
  { label: "3 năm", value: 6 },
  { label: "4 năm", value: 7 },
  { label: "5 năm", value: 8 },
];

export const tab = {
  INFO: "Thông tin chung",
  MEDAL: "Khen thưởng",
  WORKUNIT: "Lịch sử công tác",
  STAYHISTORY: "Lịch sử lưu trú",
  RELATIVE: "Thân nhân",
  DOCUMENT: "Giấy tờ/Hồ sơ",
  WELFARE: "Trợ cấp/Ưu đãi",
  WELFARE_HISTORY: "Lịch sử nhận trợ cấp",
  BATTLEFIELD: "Chiến trường",
};
export const type_data = {
  STRING: 1,
  NUMBER: 2,
  DATE: 3,
  LIST: 4,
  CATEGORY: 5,
};
export const region_role = {
  WARD: 1,
  DISTRICT: 2,
  PROVINCE: 3,
  DEPARTMENT: 4,
};
export function check_region_role(role) {
  if (role == region_role.PROVINCE) {
    return regionRole.province;
  }
  if (role == region_role.DISTRICT) {
    return regionRole.district;
  }
  if (role == region_role.WARD) {
    return regionRole.ward;
  }
  if (role == region_role.DEPARTMENT) {
    return regionRole.department;
  }
}

export function check_region_role_for_pay_file(role, value) {
  if (role == region_role.PROVINCE) {
    return value?.tmp_personal?.province?.name;
    // return regionRole.province + ": " + value?.tmp_personal?.province?.name;
  }
  if (role == region_role.DISTRICT) {
    return value?.tmp_personal?.district?.name;
    // return regionRole.district + ": " + value?.tmp_personal?.district?.name;
  }
  if (role == region_role.WARD) {
    return value?.tmp_personal?.ward?.name;
    // return regionRole.ward + ": " + value?.tmp_personal?.ward?.name;
  }
  if (role == region_role.DEPARTMENT) {
    return regionRole.department;
  }
}

export const status_approve = {
  CREATED: 1,
  NEXTROLE: 2,
  APPROVE: 3,
  REJECT: 4,
  EDIT: 5,
  DRAFT: 6,
  BACK: 7,
  NEXTSAMEROLE: 8,
  NEED_ADDITIONAL_file: 10,
  RETURNDATA: 9,
  RECEIVE: 10,
};

export const sidebardWidth = 300;

export const minColumnWidth = 80;
export const defaultColumnWidth = 150;
export const maxColumnWidth = 3000;

export const actionColumnWidth = 110;

export const gender = {
  nu: 1,
  nam: 2,
  1: "Nữ",
  2: "Nam",
  Nu: 1,
  Nam: 2,
  Khong_xac_dinh: 3,
  khong_xac_dinh: 3,
  3: "Không xác định",
};

export const is_dead = {
  Da_mat: 2,
  Con_song: 1,
};

export const is_required = {
  YES: 2,
  NO: 1,
};

export const value_type = {
  date: 3,
  number: 1,
};

export const sex_type_filter = {
  female: "1",
  male: "2",
  all: "3",
};

export const is_dead_filter = {
  alive: "1",
  dead: "2",
  all: "3",
};

export const role_id = {
  ADMIN: 1,
  DEPARTMENT_ALL: 2,
  DEPARTMENT_PROVINCE: 3,
  DEPARTMENT_DISTRICT: 4,
  DEPARTMENT_WARD: 5,
};

export const file_status = {
  PROCESSING: 1,
  APPROVED: 2,
  REJECT: 3,
  DRAFT: 4,
};

export const reception_level = {
  ADMIN: 1,
  CUC: 2,
  TINH: 3,
  HUYEN: 4,
  XA: 5,
};

export const approve_level = {
  ADMIN: 1,
  CUC: 2,
  TINH: 3,
  HUYEN: 4,
  XA: 5,
};

export const is_active = {
  ACTIVE: 2,
  UN_ACTIVE: 1,
};

export const pay_file_from = {
  CA_NHAN: 1,
  LIEN_THONG: 2,
};

export const is_receive = {
  DA_TIEP_NHAN: 2,
  CHUA_TIEP_NHAN: 1,
};

export const pay_file_status = {
  PROCESSING: 1,
  APPROVED: 2,
  REJECT: 3,
  DRAFT: 4,
  THIRD_PARTY_DATA: 5,
  RETURN_DATA: 6,
};

export const checkStatus = (value, is_receive) => {
  if (value == pay_file_status.PROCESSING) {
    if (is_receive == 2) {
      return "Đang xử lý";
    } else {
      return "Chờ tiếp nhận";
    }
  } else if (value == pay_file_status.APPROVED) {
    return "Đã phê duyệt";
  } else if (value == pay_file_status.REJECT) {
    return "Đã từ chối";
  } else if (value == pay_file_status.THIRD_PARTY_DATA) {
    return "Dữ liệu bên thứ ba";
  } else if (value == pay_file_status.RETURN_DATA) {
    return "Đã trả kết quả";
  } else {
    return "";
  }
};

export const is_pay = {
  YES: 2,
  NO: 1,
};

export const is_valid = {
  YES: 2,
  NO: 1,
};

export const money_symbol = "₫";

export const currentDate = new Date();
export const ADDITIONAL_FILE_STATUS = {
  NONE: 0,
  REQUESTING: 1,
  ADDED: 2,
};

export const lastDate = new Date("01/01/1900");

export const PHAN_LOAI_HO_SO = [
  { label: "Đủ thông tin", value: 126 },
  { label: "Thiếu thông tin hồ sơ", value: 127 },
  { label: "Thiếu thông tin mộ", value: 128 },
  { label: "Thiếu thông tin thân nhân", value: 129 },
];

export const TRANG_THAI_DI_DOI = [
  { label: "Chờ tiếp nhận", value: 1 },
  { label: "Đã tiếp nhận", value: 2 },
  { label: "Bị xóa", value: 3 },
];

export const LOAI_MO = [
  { label: "Mới tạo", value: 1 },
  { label: "Có mộ", value: 2 },
  { label: "Chưa có mộ", value: 3 },
  { label: "Đất trống", value: 4 },
];

export const NOI_HY_SINH = [
  { label: "Trong nước", value: 1 },
  { label: "Lào", value: 2 },
  { label: "Campuchia", value: 3 },
  { label: "Chưa xác định", value: 4 },
  { label: "Khác", value: 5 },
];

export const NOI_AN_TANG_BAN_DAU = [
  { label: "Trong nước", value: 1 },
  { label: "Campuchia", value: 2 },
  { label: "Lào", value: 3 },
  { label: "Khác", value: 4 },
  { label: "Chưa xác định", value: 5 },
];

export const DOI_TUONG = [
  { label: "Bộ đội", value: 1 },
  { label: "Công an", value: 2 },
  { label: "Chuyên gia", value: 3 },
  { label: "Dân quân du kích", value: 4 },
  { label: "Thanh niên xung phong", value: 5 },
  { label: "Thương binh chết do vết thương tái phát", value: 6 },
  { label: "Khác", value: 7 },
];

export const THONG_TIN_TREN_BIA_MO = [
  { label: "Mộ có đủ thông tin", value: 1 },
  { label: "Mộ có 1 phần thông tin", value: 2 },
  { label: "Mộ không có thông tin", value: 3 },
];

export const THONG_TIN_VE_MO = [
  { label: "Mộ có hài cốt", value: 1 },
  { label: "Mộ đã di chuyển", value: 2 },
  { label: "Mộ vọng", value: 3 },
  { label: "Chưa xác định", value: 4 },
];

export const CAP_QUAN_LY = [
  { label: "Tỉnh", value: 1 },
  { label: "Huyện", value: 2 },
  { label: "Xã", value: 3 },
  { label: "Khác", value: 4 },
];

export const thong_tin_them = {
  LoaiMaiTangPhi: 42,
  HinhThucNhanTien: 43,
  ThongTinNganHang: 45,
  ThanNhan: 40,
  Con: 41
}
